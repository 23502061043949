<template>
  <div class="pathway-display">
    <b-modal v-model="addThingModalOpen">
      <div class="card ">
        <div class="card-content">
          <h2 class="title is-5">
            Add a new Project
          </h2>
          <b-field>
            <b-input
              size="is-medium"
              type="text"
              placeholder="Goal"
              v-model="newThingName"
            ></b-input>
          </b-field>
          <button class=" is-large button is-primary" @click="addNewThing">
            <span> Add New Project </span>

            <span class="icon is-small">
              <i class="fas fa-plus fa-large"></i>
            </span>
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="addGoalModalOpen">
      <div class="card ">
        <div class="card-content">
          <h2 class="title is-5">
            Add to {{ this.activeProject ? this.activeProject.name : "" }}
          </h2>
          <b-field>
            <b-input
              size="is-medium"
              type="text"
              placeholder="Goal"
              v-model="newGoal"
            ></b-input>
          </b-field>
          <b-field label="Best time of day">
            <b-select
              placeholder="Best time of day"
              size="is-medium"
              expanded
              v-model="newBestTime"
            >
              <option value="Early Morning">Early Morning(3AM-7AM)</option>
              <option value="Morning">Morning(7AM-12PM)</option>
              <option value="Afternoon">Afternoon(12PM-4PM)</option>
              <option value="Evening">Evening(4PM-7PM)</option>
              <option value="Night">Night(8PM-12AM)</option>
              <option value="Late Night">Late Night(12AM-3AM)</option>
            </b-select>
          </b-field>
          <button class=" is-large button is-primary" @click="addToDo()">
            <span> Add Goal </span>

            <span class="icon is-small">
              <i class="fas fa-plus fa-large"></i>
            </span>
          </button>
        </div>
      </div>
    </b-modal>
    <div v-if="passwordCorrect">
      <div class="hero is-primary">
        <div class="hero-body">
          <h1 class="title is-1">{{ totalGoals.total }} Goals Today</h1>
          <div class="container">
            <div class="columns">
              <div class="column">
                <button
                  class=" is-large button is-active"
                  @click="activeStatus = 'ACTIVE'"
                >
                  <span class="icon is-small">
                    <i class="fas fa-star fa-large"></i>
                  </span>

                  <span> {{ totalGoals.active }} Active </span>
                </button>
              </div>
              <div class="column">
                <button
                  class=" is-large button is-success"
                  @click="activeStatus = 'COMPLETED'"
                >
                  <span class="icon is-small">
                    <i class="fas fa-check fa-large"></i>
                  </span>

                  <span> {{ totalGoals.completed }} Completed </span>
                </button>
              </div>
              <div class="column">
                <button
                  class=" is-large button is-danger"
                  @click="activeStatus = 'BLOCKED'"
                >
                  <span class="icon is-small">
                    <i class="fas fa-times fa-large"></i>
                  </span>

                  <span> {{ totalGoals.blocked }} Blocked </span>
                </button>
              </div>
            </div>
            <div class="columns">
              <div class="column"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <button
          class=" is-large button is-primary"
          @click="addThingModalOpen = true"
        >
          <span> Add New Project </span>

          <span class="icon is-small">
            <i class="fas fa-plus fa-large"></i>
          </span>
        </button>

        <draggable
          v-model="toDos"
          class="resource-box container"
          group="a"
          handle=".projecthandle"
        >
          <div v-for="project in toDos" :key="project.name">
            <div class="resource-section columns is-mobile">
              <div class="column">
                <div class="buttons projecthandle">
                  <button class="icon button is-primary">
                    <i class="fas fa-grip-lines"></i>
                  </button>
                  <h1 class="title is-3 project-title">{{ project.name }}</h1>
                </div>
              </div>
              <div class="column">
                <div class="buttons is-large">
                  <button
                    class="icon is-large button is-primary"
                    @click="openToDo(project)"
                  >
                    <i class="fas fa-plus fa-large"></i>
                  </button>
                  <button
                    class="icon is-large button is-danger"
                    @click="removeProject(project)"
                  >
                    <i class="fas fa-times fa-large"></i>
                  </button>
                </div>
              </div>
            </div>

            <div
              v-if="
                project.goals.filter((goal) => goal.status === activeStatus)
                  .length > 0
              "
            >
              <draggable
                v-model="project.goals"
                class="resource-box"
                group="b"
                handle=".handle"
              >
                <transition-group name="flip-list">
                  <div
                    v-for="(goal, i) in project.goals"
                    :key="i"
                    class="resource-row"
                  >
                    <div v-if="goal.status === activeStatus">
                      <ResourceRow
                        :name="project.name"
                        :goal="goal"
                        @completeGoal="completeGoal"
                        @deleteGoal="deleteGoal"
                        @blockGoal="blockGoal"
                        @postponeGoal="postponeGoal"
                      />
                      <div class="line-break" />
                    </div>
                  </div>
                </transition-group>
              </draggable>
            </div>
            <div v-else>No {{ activeStatus.toLowerCase() }} goals yet!</div>
          </div>
        </draggable>
      </div>
    </div>
    <div v-else>
      <h2 class="title is-5">
        What's the password?
      </h2>
      <b-field>
        <b-input
          size="is-medium"
          type="text"
          placeholder="Password"
          v-model="password"
        ></b-input>
      </b-field>
      <button class=" is-large button is-primary" @click="checkPassword">
        <span> Unlock Today </span>

        <span class="icon is-small">
          <i class="fas fa-key fa-large"></i>
        </span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/_variables.scss";

.hero {
  padding: 2rem;
}

.resource-row {
  padding: 0.5rem;
  &:hover {
    cursor: pointer;
  }
}

.line-break {
  border-bottom: 1px $primary solid;
  margin: 1.5rem !important;
  width: 88%;
  border-radius: 2rem;
  text-align: center;
  justify-content: center;
  display: inline-flex;
}

.project-title {
  font-size: 2.15rem;
  font-weight: 800;
  text-align: center;
  color: $primary;
}
.pathway-display {
  min-height: 100vh;
}

.resource-section {
  margin: 1rem 0;
}

.search-filter-box {
  display: inline-flex;

  div {
    margin-right: 1rem;

    button {
      padding: 0 2rem;
    }
  }
}
</style>

<script>
import pathwaysJSON from "@/models/mocks/pathways.json";
import draggable from "vuedraggable";
import ResourceRow from "@/components/ResourceRow.vue";
// remove for launch - import projectJSON from "@/models/mocks/data/projects.json";
import projectHelper from "@/mixins/projectHelper.js";

export default {
  name: "Todo",
  components: {
    draggable,
    ResourceRow,
  },
  mixins: [projectHelper],
  beforeDestroy() {
    localStorage.setItem("toDos", JSON.stringify(this.toDos));
  },
  mounted() {
    if (localStorage.getItem("toDos")) {
      this.toDos = JSON.parse(localStorage.getItem("toDos"));
    }

    window.addEventListener("beforeunload", this.persistToDos);
  },
  computed: {
    totalGoals() {
      let total = 0;
      let completed = 0;
      let blocked = 0;
      let active = 0;
      this.toDos.forEach((toDo) => {
        if (toDo.goals) {
          total += toDo.goals.length;
          toDo.goals.forEach((goal) => {
            if (goal.status === "COMPLETED") {
              completed += 1;
            }
            if (goal.status === "BLOCKED") {
              blocked += 1;
            }
            if (goal.status === "ACTIVE") {
              active += 1;
            }
          });
        }
      });

      return {
        total,
        active,
        completed,
        blocked,
      };
    },
  },
  data() {
    return {
      addThingModalOpen: false,
      newThingName: "",
      password: "",
      passwordCorrect: sessionStorage.getItem("passwordCorrect") || false,
      //goal info
      newGoal: "",
      newBestTime: "",
      activeStatus: "ACTIVE",
      // todo info
      toDos: [],
      activeProject: {},
      showFilters: false,
      addGoalModalOpen: false,
      showPathwayModal: false,
      results: pathwaysJSON,
    };
  },
  methods: {
    checkPassword() {
      if (this.password === "DUMILE") {
        this.passwordCorrect = true;
        sessionStorage.setItem("passwordCorrect", true);
      }
    },
    removeProject(project) {
      const projects = this.toDos;

      this.toDos = projects.filter((todo) => {
        return project.name != todo.name;
      });
    },
    addNewThing() {
      this.addProject("toDos");
    },
    toggleFilter() {
      this.showFilters = !this.showFilters;
    },
    persistToDos() {
      localStorage.setItem("toDos", JSON.stringify(this.toDos));
    },
    openToDo(project) {
      this.addGoalModalOpen = true;
      this.activeProject = project;
    },
    addToDo() {
      let todos = this.toDos;

      todos
        .filter((todo) => todo.name === this.activeProject.name)[0]
        .goals.push({
          label: this.newGoal,
          timeOfDay: this.newBestTime,
          status: "ACTIVE",
        });

      this.toDos = todos;
      this.newBestTime = "";
      this.newGoal = "";
      this.addGoalModalOpen = false;
    },
    blockGoal(projectName, goal, note) {
      let todos = this.toDos;

      const project = todos.filter((todo) => todo.name === projectName)[0];

      const goalIndex = project.goals.indexOf(goal);

      project.goals[goalIndex].status = "BLOCKED";
      project.goals[goalIndex].blockedNote = note;
      project.goals[goalIndex].timesBlocked += 1;
    },
    completeGoal(projectName, goal) {
      let todos = this.toDos;

      const project = todos.filter((todo) => todo.name === projectName)[0];

      const goalIndex = project.goals.indexOf(goal);

      project.goals[goalIndex].status = "COMPLETED";
    },
    postponeGoal(projectName, goal, note) {
      let todos = this.toDos;

      const project = todos.filter((todo) => todo.name === projectName)[0];

      const goalIndex = project.goals.indexOf(goal);

      project.goals[goalIndex].status = "POSTPONED";
      project.goals[goalIndex].postponeNote = note;
      project.goals[goalIndex].timesPostponed += 1;
    },
    deleteGoal(projectName, goal) {
      let todos = this.toDos;

      const project = todos.filter((todo) => todo.name === projectName)[0];

      const goalIndex = project.goals.indexOf(goal);

      project.goals.splice(goalIndex, 1);

      todos.filter((todo) => todo.name === projectName)[0] = project;

      this.toDos = todos;
    },
  },
};
</script>
