import Vue from "vue";
import VueRouter from "vue-router";
// Add back the Home with legit launch
// import Home from "../pages/Home.vue";
import Todo from "../pages/Todo.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Todo,
  },
  {
    path: "/today",
    name: "Todo",
    component: Todo,
  },
  {
    path: "/signin",
    name: "Sign In",
    component: () =>
      import(/* webpackChunkName: "signin" */ "../pages/Auth/SignIn.vue"),
  },
  {
    path: "/create-pathway",
    name: "Create Pathway",
    component: () =>
      import(
        /* webpackChunkName: "createpathway" */ "../pages/Expert/PathwayCreate.vue"
      ),
  },
  {
    path: "/bridge-year",
    name: "Bridge Year",
    component: () =>
      import(/* webpackChunkName: "bridgeyear" */ "../pages/BridgeYear.vue"),
  },

  {
    path: "/verify",
    name: "Verify Account",
    component: () =>
      import(
        /* webpackChunkName: "signin" */ "../pages/Auth/VerifyAccount.vue"
      ),
  },
  {
    path: "/request-new-verification",
    name: "Request new Verification",
    component: () =>
      import(
        /* webpackChunkName: "signin" */ "../pages/Auth/RequestNewVerification.vue"
      ),
  },
  {
    path: "/projects",
    name: "Projects",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "pathwayDiscovery" */ "../pages/Projects.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
