import Vue from "vue";
import App from "./App.vue";
import Buefy from "buefy";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import "buefy/dist/buefy.css";
import Amplify, { Auth } from "aws-amplify";

const USER_POOL_ID = "us-west-2_ROPb0722H";
const USER_POOL_WEB_CLIENT_ID = "7r1oqhpe0t9c048bibqp7t1g65";

Amplify.configure({
  aws_project_region: "us-west-2",
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: USER_POOL_ID,
  aws_user_pools_web_client_id: USER_POOL_WEB_CLIENT_ID,
});

Auth.configure({
  // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  userPoolId: USER_POOL_ID,
  region: "us-west-2",
  userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
  authenticationFlowType: "USER_PASSWORD_AUTH",
});

Vue.config.productionTip = false;
Vue.use(Buefy);

const globalVar = {
  uri: "https://ijodwz01ib.execute-api.us-west-2.amazonaws.com/api/",
};

Vue.prototype.globalVar = globalVar;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
