const projectHelper = {
  methods: {
    addProject(dataPoint) {
      const newDos = this[dataPoint];
      newDos.push({
        name: this.newThingName,
        goals: [],
      });

      this[dataPoint] = newDos;
      this.addThingModalOpen = false;
    },
  },
};

export default projectHelper;
