<template>
  <b-navbar class="mainnav" type=" is-primary">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
          class="image"
          src="../assets/youngfactorlogo.png"
          alt="Young Factors"
        />
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item href="/today">
        Today
      </b-navbar-item>
      <!--

      <b-navbar-item href="/projects">
        Projects
      </b-navbar-item>
      <b-navbar-dropdown label="My Squads">
        <b-navbar-item href="/pathway/dank-coder-javascript">
          Dank Coder Javascript Path
        </b-navbar-item>
        <b-navbar-item href="/pathway/rethink-the-world">
          Rethink the World Python Path
        </b-navbar-item>
      </b-navbar-dropdown>
      <b-navbar-dropdown label="My Projects">
        <b-navbar-item href="/bridge-year">
          Bridge Year
        </b-navbar-item>
        <b-navbar-item href="/corporate-workshops">
          Corporate Workshops
        </b-navbar-item>
        <b-navbar-item href="/industry-simulator">
          Industry Simulators
        </b-navbar-item>
      </b-navbar-dropdown>
      -->
    </template>
    <template slot="end" v-if="false">
      <b-navbar-item v-if="isLoggedIn()" @click="logout">
        LogOut
      </b-navbar-item>
      <b-navbar-item href="/signin" v-else>
        Login
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import authHelper from "@/mixins/authHelper";

export default {
  name: "Navbar",
  mixins: [authHelper],
  methods: {
    async logout() {
      this.$store.dispatch("signOut");
    },
  },
};
</script>
