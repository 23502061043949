<template>
  <div>
    <b-modal v-model="blockedModalOpen">
      <div class="card">
        <div class="card-content">
          <h2 class="title is-5">
            Add a note about your issue
          </h2>
          <b-field>
            <b-input
              size="is-medium"
              type="text"
              placeholder="Note"
              v-model="blockedNote"
            ></b-input>
          </b-field>

          <button class=" is-large button is-primary" @click="blockGoalHandler">
            <span class="icon is-small">
              <i class="fas fa-times fa-large"></i>
            </span>
            <span> Add Blocker </span>
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="nextDayModalOpen">
      <div class="card">Write a note about your issue</div>
    </b-modal>

    <div class="columns is-multiline is-mobile">
      <div class="column is-3 buttons is-medium is-half-mobile">
        <button class="icon button is-primary handle">
          <i class="fas fa-grip-lines"></i>
        </button>

        <button class="icon button is-success" @click="completeGoalHandler">
          <i class="fas fa-check"></i>
        </button>
        <button class="icon button is-danger" @click="blockedModalOpen = true">
          <i class="fas fa-times"></i>
        </button>
        <button class="icon button is-primary" @click="nextDayModalOpen = true">
          <i class="fas fa-arrow-right"></i>
        </button>
      </div>
      <div class="column is-2 is-half-mobile">
        <div v-if="this.isEdit">
          <b-field>
            <b-input
              size="is-medium"
              type="text"
              :placeholder="goal.label"
              v-model="goal.label"
            ></b-input>
          </b-field>
        </div>
        <div class="label" v-else>
          {{ goal.label }}
        </div>
      </div>
      <div class="column is-4">
        <div v-if="this.isEdit">
          <b-field>
            <b-select
              placeholder="Best time of day"
              size="is-medium"
              expanded
              v-model="goal.timeOfDay"
            >
              <option value="Early Morning">Early Morning(3AM-7AM)</option>
              <option value="Morning">Morning(7AM-12PM)</option>
              <option value="Afternoon">Afternoon(12PM-4PM)</option>
              <option value="Evening">Evening(4PM-7PM)</option>
              <option value="Night">Night(8PM-12AM)</option>
              <option value="Late Night">Late Night(12AM-3AM)</option>
            </b-select>
          </b-field>
        </div>
        <div class="label" v-else>
          {{ goal.timeOfDay }}
        </div>
      </div>

      <div class="column is-3 buttons is-medium" @click="toggleEdit">
        <button
          class="icon button "
          :class="{ 'is-primary': !this.isEdit, 'is-success': this.isEdit }"
        >
          <i
            class="fas"
            :class="{ 'fa-pen': !this.isEdit, 'fa-check': this.isEdit }"
          ></i>
        </button>

        <button class="icon button is-danger" @click="deleteGoalHandler">
          <i class="fas fa-trash"></i>
        </button>

        <button
          class="icon button is-primary"
          @click="
            () => {
              this.isOpen = !this.isOpen;
            }
          "
        >
          <i :class="isOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
        </button>
      </div>
    </div>
    <transition name="fade">
      <div class="card-content" v-if="isOpen">
        <div class="content">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec
          iaculis mauris.
        </div>
      </div>
    </transition>
  </div>
</template>
<style scoped lang="scss">
@import "@/_variables.scss";

.flip-list-move {
  transition: transform 0.5s;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
export default {
  name: "ResourceRow",
  props: [
    "name",
    "goal",
    "activeStatus",
    "completeGoal",
    "blockGoal",
    "deleteGoal",
  ],
  data() {
    return {
      isOpen: false,
      nextDayModalOpen: false,
      blockedModalOpen: false,
      isEdit: false,
      blockedNote: "",
    };
  },
  methods: {
    deleteGoalHandler() {
      this.$emit("deleteGoal", this.name, this.goal);
    },
    toggleEdit() {
      if (this.isEdit) {
        this.isEdit = false;
      } else {
        this.isEdit = true;
      }
    },
    blockGoalHandler() {
      this.$emit("blockGoal", this.name, this.goal, this.blockedNote);
    },
    completeGoalHandler() {
      this.$emit("completeGoal", this.name, this.goal);
    },
  },
};
</script>
