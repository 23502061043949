import { Auth } from "aws-amplify";
const authHelper = {
  methods: {
    /**
     * Finds if window is mobile
     */
    isLoggedIn: function() {
      const prefix = localStorage.getItem("preetsFabrics");
      const lastAuthUser = localStorage.getItem(`${prefix}.LastAuthUser`);
      return lastAuthUser;
      // return value
    },
    getAuthAccessToken: async function() {
      const sesh = await Auth.currentSession();

      const token = sesh.accessToken.jwtToken;

      return token;
    },
    getAuthIDToken: async function() {
      const sesh = await Auth.currentSession();

      const token = sesh.idToken.jwtToken;

      return token;
    },
  },
};
export default authHelper;
