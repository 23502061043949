<template>
  <div id="app">
    <Navbar />
    <router-view />
  </div>
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Lobster&display=swap");

@import "./_variables.scss";

#app {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $font-color;
  background-color: $background;
}

// Global stepper related bug fix
a.step-link {
  span.step-title {
    color: $font-color;
  }
}

h1,
h2,
h3,
.button {
  font-family: "Lobster", sans-serif;
}
.title {
  color: $font-color;
  font-size: 4.2rem;
}

nav.oldnav {
  background-color: $background;
  padding: 1rem;
  .navbar-item,
  .navbar-item.has-dropdown {
    color: $font-color;
    background-color: $background;

    &:hover {
      background-color: $background;
      color: $font-color;
      .navbar-link {
        background-color: $background;
        color: $font-color;
      }
    }

    .navbar-link {
      background-color: $background;
      color: $font-color;
    }

    .navbar-dropdown {
      color: $font-color;
      border-color: $primary;
      border: 0;
      padding: 0;
    }
  }
}
</style>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "App",
  components: {
    Navbar,
  },
};
</script>
