import Vue from "vue";
import Vuex from "vuex";
import { Auth } from "aws-amplify";
import router from "@/router";
import authHelper from "@/mixins/authHelper";

Vue.use(Vuex);

const globalVar = {
  uri: "https://ijodwz01ib.execute-api.us-west-2.amazonaws.com/api/",
};

export default new Vuex.Store({
  state: {
    user: null,
  },
  mutations: {
    updateUser(state, payload) {
      state.user = payload;
    },
  },
  getters: {
    isSignedIn(state) {
      return state.user;
    },
  },
  actions: {
    async signUp(context, payload) {
      try {
        await Auth.signUp({
          username: payload.username,
          password: payload.password,
          attributes: {
            email: payload.username, // optional
          },
        });
      } catch (error) {
        console.log("error signing up:", error);
      }

      localStorage.setItem("email", payload.username);
      router.push("/verify");
    },
    async confirmSignup(context, payload) {
      console.log(payload);
      try {
        const response = await Auth.confirmSignUp(
          localStorage.getItem("email"),
          payload.code
        );
        // if it's a success then,
        console.log(response);
      } catch (error) {
        console.log("error verifying:", error);
      }
      // go to sign in
      router.push("/signin");
    },
    async signIn(context, payload) {
      try {
        const response = await Auth.signIn({
          username: payload.username,
          password: payload.password,
        });
        localStorage.setItem("preetsFabrics", response.keyPrefix);

        // post bridge data, if there's bridge data
        if (localStorage.getItem("bridgeData")) {
          this.dispatch("createBridge");
        } else {
          router.push("/");
        }
      } catch (error) {
        console.log("error signing in:", error);
        return error;
      }
    },
    async createBridge() {
      const token = await authHelper.methods.getAuthIDToken();

      try {
        const bridge = await fetch(`${globalVar.uri}bridge`, {
          method: "POST",
          body: localStorage.getItem("bridgeData"),
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });

        if (bridge.status === 200) {
          router.push("/");
        }
      } catch (e) {
        // handle error here
        console.log("error");
        console.log(e);
      }
    },
    async signOut() {
      try {
        await Auth.signOut();
        localStorage.removeItem("preetsFabrics");
        router.push("/signin");
      } catch (error) {
        console.log("error signing out: ", error);
      }
    },
    async updateAuth() {
      Auth.currentAuthenticatedUser().then((user) => {
        this.commit("updateUser", user);
      });
    },
  },
  modules: {},
});
